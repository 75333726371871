.recognitions{
    padding: 50px 0;

    @media screen and (min-width: $min-md) {
        padding: 80px 0;
        height: 350px;
    }

    &__wrapper{
        display: flex;
        justify-content: space-between;
        // align-items: flex-start;
        // flex-wrap: wrap;
    }
}

.recognitions-card{
    width: 100%;
    
    margin-right: 10px;
    transition: all 0.3s ease;
    position: relative;
    z-index: 2;
    @media screen and (min-width: $min-md) {
        width: 23%;
    }

    &__img{
        background-color: $secondary-color;
        color: white;
        padding: 20px;
        width: 100%;
        height: 150px;
        transition: all 0.3s ease;
        transform: translateY(75px);
        position: relative;
        z-index: 1;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content{
        background-color: $primary-color;
        color: white;
        padding: 20px;
        // padding-top: 20px;
        box-sizing: border-box;
        height: 150px;
        overflow: hidden;
        // opacity: 0;
        text-align: center;
        transition: all 0.3s ease;
        transform: translateY(-75px);
        position: relative;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1);


        p{
        transition: all 0.3s ease;
        }

    }

    &:hover{
        @media screen and (min-width: $min-md) {
            // transform: scale(1.1);
        }

        .recognitions-card__img{
            // height: 100px;
            // transform: translateY(0);
            box-shadow: 0 20px 50px rgba(0, 0, 0, 0.3);

        }
        // .recognitions-card__content{
        //     // padding-top: 20px;
        //     // height: auto;
        //     // opacity: 1;
        //     transform: translateY(0);

        // }
    }
}

.n-container {
    width: 1000px;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.n-container .n-card {
    position: relative;
    border-radius: 10px;
}

.n-container .n-card .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f00;
    transition: 0.7s;
    z-index: 1;
}

.n-container .n-card:nth-child(1) .icon {
    background: #e07768;
}

.n-container .n-card:nth-child(2) .icon {
    background: #6eadd4;
}

.n-container .n-card:nth-child(3) .icon {
    background: #4aada9;
}


.n-container .n-card .icon .fa {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    transition: 0.7s;
    color: #fff;
}

i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    transition: 0.7s;
    color: #fff;
}

.n-container .n-card .face {
    width: 300px;
    height: 200px;
    transition: 0.5s;
}

.n-container .n-card .face.face1 {
    position: relative;
    background: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transform: translateY(100px);
}

.n-container .n-card:hover .face.face1{
    background: #ff0057;
    transform: translateY(0px);
}

.n-container .n-card .face.face1 .content {
    opacity: 1;
    transition: 0.5s;
}

.n-container .n-card:hover .face.face1 .content {
    opacity: 1;
}

.n-container .n-card .face.face1 .content i{
    max-width: 100px;
}

.n-container .n-card .face.face2 {
    position: relative;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 20px 50px rgba(0,0,0,0.8);
    transform: translateY(-100px);
}

.n-container .n-card:hover .face.face2{
    transform: translateY(0);
}

.n-container .n-card .face.face2 .content p {
    margin: 0;
    padding: 0;
    text-align: center;
    color: #414141;
}

.n-container .n-card .face.face2 .content h3 {
    margin: 0 0 10px 0;
    padding: 0;
    color: #fff;
    font-size: 24px;
    text-align: center;
    color: #414141;
} 

.n-container a {
    text-decoration: none;
    color: #414141;
}
