@import './variable.scss';

body {
    margin: 0;
    padding: 0;
    font-family: $font-inter;
    line-height: 1.75;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0;
    padding: 0;
}

h1{
    font-size: 40px;
    color: $title;
    margin-bottom: 30px;
}
h2{
    font-size: 32px;
    color: $title;
    margin-bottom: 30px;
}
h3{
    font-size: 28px;
    color: $title;
    margin-bottom: 30px;
}
h4{
    font-size: 24px;
    color: $title;
    margin-bottom: 30px;
}
h5{
    font-size: 20px;
    color: $title;
    margin-bottom: 30px;
}
h6{
    font-size: 18px;
    color: $title;
    margin-bottom: 30px;
}
a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

p {
    font-size: 16px;
    font-weight: 400;
    // color: $text;

    a{
        color: $primary-color;
        font-weight: 500;
    }
}

ul {
    padding-left: 30px;

    li {
        padding-inline: 0 20px;
        position: relative;
        color: $primary-color;
        font-weight: 500;
        margin-bottom: 10px;
        &::marker {
            color: $primary-color;
            font-size: 1.5rem;
            line-height: 1.2;
        }
        
    }
}

//container
.container-xxl {
    max-width: $min-xxl;
    margin: 0 auto;
    padding: 0 15px;

    @media screen and (min-width: 760px) {
        padding: 0 20px;
    }
}

.container {
    max-width: $min-xxl;
    margin: 0 auto;
    padding: 0 15px;

    @media screen and (min-width: 760px) {
        padding: 0 20px;
    }
}

.container-lg {
    max-width: $min-lg;
    margin: 0 auto;
    padding: 0 15px;

    @media screen and (min-width: 760px) {
        padding: 0 20px;
    }
}

.container-md {
    max-width: $min-md;
    margin: 0 auto;
    padding: 0 15px;

    @media screen and (min-width: 760px) {
        padding: 0 20px;
    }
}

//buttons

.btn {
    border-style: none;
    text-decoration: none;
    outline: 0;
    overflow: hidden;
    position: relative;
    align-items: center;
    display: inline-flex;
    padding: 13px 18px;
    font-weight: 600;
    background-color: $primary-color;
    color: $white;
    border-radius: 50px;
    font-size: 13px;
    line-height: 1;
    width: 100%;
    max-width: max-content;


    @media screen and (min-width: 760px) {
        padding: 18px 25px;
        font-size: 18px;
    }

    @media screen and (min-width: 1400px) {
        // padding: 15px 40px;
        // font-size: 20px;
    }

    .btn-wrapper {
        transition: opacity .2s, transform .2s;
        transition-timing-function: cubic-bezier(.455, .03, .515, .955);
    }

    &::after {
        content: attr(data-text);
        display: inline-block;
        position: absolute;
        top: 50%;
        opacity: 0;
        transform: translate(0, 100%);
        transition: opacity .2s, transform .2s;
        transition-timing-function: cubic-bezier(.455, .03, .515, .955);
        white-space: nowrap
    }

    &:hover {
        color: $primary-color;
        background-color: $white;

        .btn-wrapper {
            transform: translateY(-150%);
            opacity: 0;
        }

        &::after {
            transform: translate(0%, -50%);
            opacity: 1;
        }

    }

    &.dark-bg{
        &:hover{
            color: $white;
            background-color: $primary-color;
        }
    }

    &.full {
        width: 100%;
        max-width: 100%;
        justify-content: center;
    }

}

.btn-dark {
    @extend .btn;
    background-color: $primary-color;

    &:hover {
        color: $primary-color;
        background-color: $secondary-color ;
    }

}

.btn-light {
    @extend .btn;
    background-color: $bg;
    color: $primary-color;

    &:hover {
        color: $text ;
        background-color: $new-yellow ;
    }

}

.btn-border {
    @extend .btn;
    background-color: transparent;
    color: $primary-color;
    border: 2px solid $primary-color;
    padding: 10px 13px;
    font-size: 16px;

    @media screen and (min-width: 760px) {
        padding: 18px 25px;
        font-size: 20px;
    }
    &:hover {
        color: $secondary-color;
        background-color: $primary-color;
    }

}
.link {
    padding: 0;
    color: $primary-color;
    background-color: #FFFFFF00;
    border-style: none;
    position: relative;
    text-decoration: none;
    outline: 0;
    font-size: 15px;
    transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    font-weight: 600;
    display: inline-block;

    &::after {
        content: "";
        position: absolute;
        // top: 50%;
        bottom: 0;
        margin-top: calc(0.5em - -3px);
        left: 0;
        width: 100%;
        height: 2px;
        opacity: .8;
        transform: scale3d(0, 1, 1);
        transform-origin: 100% 50%;
        transition: transform .3s ease-in-out;
        transition-timing-function: cubic-bezier(.2, 1, .3, 1);
        background: $secondary-color;
    }

    &:hover {
        &::after {
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 50%;
            transition-timing-function: ease;
        }
    }

    &.light {
        color: $white;
        font-size: 16px;
        font-weight: 400;
    }
}

//headings

.main-title {
    font-size: 32px;
    font-weight: 600;
    font-family: $font-outfit;
    letter-spacing: 0.5px;


    @media screen and (min-width:760px) {
        font-size: 60px;
    }
}

.main-title-50 {
    font-size: 32px;
    font-weight: 600;
    font-family: $font-outfit;
    letter-spacing: -0.015em;
    color: $title;

    @media screen and (min-width:760px) {
        font-size: 50px;
    }
}

.title {
    font-size: 24px;
    font-weight: 500;
    font-family: $font-outfit;
    color: $title;
    margin-bottom: 25px;
    letter-spacing: 0.5px;

    @media screen and (min-width:760px) {
        font-size: 40px;
    }

    span {
        display: inline-block;
        position: relative;
        z-index: 1;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $secondary-color;
            z-index: -1;

            @media screen and (min-width:760px) {
                height: 5px;
            }
        }
    }
}

.subtitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    color: $title;
    margin-bottom: 12px;

    @media screen and (min-width:760px) {
        font-size: 24px;
    }
}

.sub-subtitle {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    color: $title;
    margin-bottom: 12px;

    @media screen and (min-width:760px) {
        font-size: 18px;
    }
}

.large-paragraph {
    font-size: 18px;
    font-weight: 400;
}

.mini-text {
    display: inline-block;
    color: $primary-color;
    font-size: 11px;
    border-radius: 10px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 11px;
    letter-spacing: 0.04em;
    padding: 9px 12px;
    background-color: #df9a625f;
    margin-bottom: 14px;
    transition: all 0.3s ease;


    &.light {
        color: $white;
        background-color: #936BFF1A;
    }
}

.view-btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.clear-fix {
    display: block;
    background: transparent;
    height: 70px;

    @media screen and (min-width: $min-md) {
        height: 80px;
    }
}

.loader-wrapper{
    display: flex;
    width: 100%;
    height: 500px;
    align-items: center;
    justify-content: center;
}

//error
.error{
    font-size: 10px;
    color: red;
    display: block;
}