.privacy-policy{
    padding: 70px 0;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    ul{
        list-style: disc !important;
        padding-left: 25px !important;
        li{
            color: $black !important;
            font-weight: 400;
            padding-left: 0 !important;

            a{
                color: $primary-color;
                font-weight: 500;
            }
            &::after{
                display: none !important;
            }

            strong{
                color: $secondary-color;
            }
        }
    }

    &__navigation {
        width: 100%;
        position: sticky;
        top: 70px;
        z-index: 2;
        background-color: $white;
        display: none;
        
        padding: 0 0 20px;
        border-bottom: 1px solid #E7E7E7;

        @media screen and (min-width: $min-md) {
            display: block;
            top: 100px;
            width: 30%;
            border-bottom:unset;

        }
    }

    &__wrapper{
        width: 100%;

        @media screen and (min-width: $min-md) {
            width: 70%;
            padding-left: 50px;
        }
    }

    &__nav {
        display: block;
        color: $text;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 15px;
        position: relative;
        width: max-content;
        transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        padding-bottom: 2px;

        &:hover {
            color: $primary-color;
        }

        &.active {
            color: $primary-color;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $primary-color;
            }
        }
    }

    &__condition{
        padding-block: 50px;
        border-bottom: 1px solid #E7E7E7;

        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }

        @media screen and (min-width: $min-md) {
            
            padding-block: 50px;
        }
    }
}

