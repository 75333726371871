.customers {
    padding: 80px 0;

    &__wrappers {
        display: flex;
        flex-wrap: wrap;
        @media screen and (min-width: $min-md) {
            flex-wrap: nowrap;
        }
    }
}

.customer-card {
    position: relative;
    padding: 20px;
    cursor: pointer;
    width: 100%;
    min-width: 125px;
    overflow: hidden;
    transition: all 0.15s ease-in-out;
    display: flex;
    align-items: flex-end;
    color: $white;
    height: 300px;

    @media screen and (min-width: $min-sm) {
        width: 50%;
    }

    @media screen and (min-width: $min-md) {
        width: 25%;
        height: 500px;
    }

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 70%;
        left: 0;
        bottom: 0;
        background: linear-gradient(rgba(30, 13, 50, 0) 0%, rgba($primary-color, 0.99) 100%);
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    &:hover {

        @media screen and (min-width: $min-md) {
            width: 50%;
            min-width: 135px;

            .customer-card__content {
                transform: translateX(0%);
            }
    
            .customer-card__main-title {
                opacity: 0;
            }
        }
    }

    &__main-title {
        // display: flex;
        // align-items: flex-end;
        color: $white;
        transform: rotate(-90deg);
        transform-origin: 0px 0px;
        position: relative;
        transition: all 0.2s ease-in-out;
        opacity: 0;
        z-index: 1;
        @media screen and (min-width: $min-md) {
            opacity: 1;
        }
    }

    &__content {
        position: absolute;
        z-index: 1;
        color: $white;
        min-height: 20%;
        left: 20px;
        right: 20px;
        bottom: 20px;
        transform-origin: 0px center;
        transition: all 0.5s ease-in;
        @media screen and (min-width: $min-md) {
            transform: translateX(-150%);
        }

        p {
            color: $white;
        }
    }

    &__title{
        color: $white;
        margin-bottom: 20px;
    }
}