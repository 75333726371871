.conditions-section{
    padding: 70px 0;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    &__navigation {
        width: 100%;
        position: sticky;
        top: 70px;
        z-index: 2;
        background-color: $white;
        display: none;
        // flex-direction: column;
        // align-items: center;
        justify-content: space-around;
        padding: 0 0 20px;
        border-bottom: 1px solid #E7E7E7;

        @media screen and (min-width: $min-lg) {
            display: block;
            top: 100px;
            width: 30%;
            border-bottom:unset;

        }
    }

    &__wrapper{
        width: 100%;

        @media screen and (min-width: $min-lg) {
            width: 70%;
            padding-left: 50px;
        }
    }

    &__nav {
        display: block;
        color: $text;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 15px;
        position: relative;
        width: max-content;
        transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        padding-bottom: 2px;

        &:hover {
            color: $primary-color;
        }

        &.active {
            color: $primary-color;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $primary-color;
            }
        }
    }

    &__condition{
        padding-block: 20px;

        &:last-child {
            padding-bottom: 0;
        }

        @media screen and (min-width: $min-lg) {
            &:first-child {
                padding-top: 0;
            }
            padding-block: 50px;
        }
    }
    h2{
        border-bottom: 1px solid #7575756b;
        padding-bottom: 10px;
    }
    article{
        padding:20px 0;

        @media screen and (min-width: $min-md) {
            padding:20px 30px 0;
        }
    }
}

