/* src/components/NotFound.css */
.not-found {
    text-align: center;
    margin-top: 50px;
  }
  
  .not-found h1 {
    font-size: 2.5em;
    color: #333;
  }
  
  .not-found p {
    font-size: 1.2em;
    color: #666;
  }
  
  .not-found a {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    color: #fff;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .not-found a:hover {
    background-color: #0056b3;
  }
  