.pricing-card {
    box-shadow: 0px 20px 60px 0px rgba(0.9999999999999865, 30.000000000000014, 81, 0.0784313725490196);
    border: unset;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    flex: 1 1 calc(100% - 20px);

    @media screen and (min-width: $min-md) {
        flex: 1 1 calc(33.33% - 20px);
        margin-right: 20px;
        margin-bottom: 20px;
        min-height: 586.59px;
    }

    &:hover {
        box-shadow: 0px 20px 60px 0px rgba(1, 30, 81, 0.2);
    }

    h2 {
        margin-bottom: 30px;
    }

    &__list {
        margin-bottom: 40px;

        li {
            font-size: 12px;
            padding: 6px 0;
            border-bottom: 1px solid #e7e7e7;

            &::after {
                display: none;
            }

            &:last-child {
                border-bottom: unset;
                padding-bottom: 0;
            }
        }
    }

    .card-body {
        padding: 40px 30px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    &__amount {
        color: $secondary-color;
        font-weight: 600;
        margin-block: 30px;
        // font-size: 40px;

        sup {
            font-size: 16px;
            top: -1.5em !important;
            margin-right: 5px;
        }

        .value {
            display: inline-block;
            font-size: 40px;
            line-height: 1;
        }

        .duration {
            margin-left: 5px;
        }
    }
}

.readBtn {
    background-color: transparent;
    border: unset;
    padding: 0;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        color: $primary-color;
    }
}