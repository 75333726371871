.pricing-grid {
    padding: 60px 0;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 50%;
        background-color: $bg;
        top: 0;
        left: 0;
        z-index: -1;

    }

    @media screen and (min-width: $min-md) {
        padding-block: 0 100px;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;

        @media screen and (min-width: $min-md) {
            // grid-template-columns: repeat(3, 1fr);
        }
    }
}

// comparison-table
.comparison-table {
    padding-bottom: 100px;

    &__wrapper {
        position: relative;
    }

    &__top,
    &__rows {
        display: flex;
        justify-content: space-between;
        padding: 30px;
    }

    &__top {
        border-top: 1px solid #E7E7E7;
        border-bottom: 1px solid #E7E7E7;
        background-color: $white;
        position: sticky;
        top: 70px;
        z-index: 2;

        @media screen and (min-width: $min-md) {
            top: 78px;
        }

    }

    &__rows {
        // padding: 30px;
        // background-color: $bg;
    }

    &__title-col {
        width: 40%;

        p {
            margin-bottom: 0;
            color: $secondary-color;
        }
    }

    &__data-col {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        padding-inline: 10px;
    }

    .plan,
    .value {
        display: block;
        color: $secondary-color;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.3;

        // width: 100%;
    }

    .value {
        margin-top: 10px;
    }

    .info {
        display: block;
        font-size: 16px;
        font-weight: 600;
        color: $secondary-color;
    }
}

//pricing-faq

.pricing-faq {
    padding-block:60px 40px;
    background-color: $bg;

    @media screen and (min-width: $min-md) {
        padding-block:100px 80px;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        column-gap: 20px;

        @media screen and (min-width: $min-md) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}