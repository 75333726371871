.our-teams {
    padding-block: 50px 100px;
    position: relative;

    // &__wrapper {
    //     display: grid;
    //     grid-template-columns: repeat(1, 1fr);
    //     gap: 20px;

    //     @media screen and (min-width: $min-md) {
    //         grid-template-columns: repeat(2, 1fr);
    //     }

    //     @media screen and (min-width: $min-lg) {
    //         grid-template-columns: repeat(3, 1fr);

    //     }
    // }


    .slick-prev {
        left: 0;

        @media screen and (min-width: $min-md) {
            left: -25px;
        }
        &::before {
            color: $primary-color;
        }
    }

    .slick-next {
        right: 5px;
        @media screen and (min-width: $min-md) {
            right: -25px ;
        }
        &::before {
            color: $primary-color;
        }
    }

}

.custom-scrollbar {
    margin-top: 15px;
    position: relative;
}

.scrollbar-track {
    width: 100%;
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 4px;
    overflow-x: scroll;
    position: relative;
    cursor: pointer;
}

.scrollbar-thumb {
    height: 100%;
    background-color: #007bff;
    border-radius: 4px;
    width: 10%;
    /* Dynamic width set in JS based on slides */
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.3s ease, left 0.3s ease;
}


.teams-card {

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    @media screen and (min-width: $min-md) {
        padding: 0;

    }

    &__img {
        width: 100%;
        height: 250px;
        overflow: hidden;
        border-radius: 16px;
        border: 1px solid #e5dddd;
        margin-bottom: 20px;
        @media screen and (min-width: $min-md) {
            width: 35%;
            margin-bottom: 0;

        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

    }

    &__info {
        width: 100%;
        transition: all 0.3s ease-in-out;
        padding-right: 0;
        position: relative;
        padding-bottom: 50px;
        @media screen and (min-width: $min-md) {
            width: 55%;
            padding-right: 50px;

        }

        &__top {
            border-bottom: 2px solid #d4d8de;
            margin-bottom: 20px;
            padding-bottom: 10px;
        }
    }

    &__member-name {
        font-weight: 400;
        font-size: 24px;
        line-height: 1;
        letter-spacing: -0.027em;
        margin-bottom: 4px;
    }

    .position {
        font-size: 16px;
        color: $text-grey;
    }

    &__social-links {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;
    }

    &__link {
        color: $white;
        background-color: $primary-color;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        font-size: 12px;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 10px;

        img {
            object-fit: contain;
            height: 16px;
            width: 16px;
        }

        svg {
            width: 16px;
            height: 16px;
            object-fit: contain;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}