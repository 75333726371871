.integration{
    background-color: $bg;
    padding-block: 40px;

    @media screen and (min-width: $min-md) {
        padding-block: 110px;
    }
    &__grid{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        @media screen and (min-width: $min-md) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (min-width: $min-lg) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .mini-text{
        background-color: #936BFF1A ;
    }
}
.integration-card{
    background-color: $white;
    padding:28px;
    box-shadow: 0px 20px 60px 0px rgba(0.9999999999999865, 30.000000000000014, 81, 0.08);
    border: 1px solid #011E511A;
    border-radius: 20px;
    cursor: default;
    transition: all 0.3s ease;

    @media screen and (min-width: $min-md) {
        padding:40px;
    }
    p{
        color: $text;
    }
    &__logo{
        width: 80px;
        height: 60px;
        margin-bottom: 20px;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &:hover{
        box-shadow: 0px 20px 60px 0px rgba(0.9999999999999865, 30.000000000000014, 81, 0.4);

    }
}