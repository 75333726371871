.navigation {
    background-color: transparent;
    border: none;
    padding: 15px 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    height: 70px;
    box-shadow: 0px 20px 60px -20px rgba(0.9999999999999865, 30.000000000000014, 81, 0.08);

    &.scrolled{
        background-color: white;

    }
    @media screen and (min-width: $min-md) {
        height: 80px;
    }

    &__nav-items {
        display: flex;
        align-items: center;


    }

    .logo {
        margin-right: 40px;
        height: 30px;
        @media screen and (min-width: $min-md) {
            height: 40px;
        }
        img {
            height: 100%;
        }
    }

    .nav-item {
        font-size: 16px;
        font-weight: 300;
        line-height: 1;
        margin-right: 20px;
        color: $primary-color;
        display: none;
        transition: all 0.3s ease;

        @media screen and (min-width: $min-md) {
            display: inline-block;
        }

        &:hover{
            text-decoration: underline;
            // color: $primary-color;
        }
    }
    .nav-btn{
        display: none;

        @media screen and (min-width: $min-md) {
            display: flex;
        }
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .hamburger {
        width: 30px;
        height: 30px;
        position: relative;
        cursor: pointer;
        border: none;
        outline: none;
        background-color: transparent;
        display: block;
        @media screen and (min-width: $min-md) {
            display: none;
        }
    }
    .menu-line,
    .menu-line:before,
    .menu-line:after {
        content: '';
        display: block;
        width: 25px;
        height: 2px;
        background-color: #000;
        position: absolute;
        transition: transform 0.3s ease;
        
    }

    .menu-line:before {
        top: -8px;
    }

    .menu-line:after {
        top: 8px;
    }

    .hamburger.active .menu-line {
        transform: rotate(45deg);
      }
      
      .hamburger.active .menu-line:before {
        top: 0;
        transform: rotate(-90deg);
      }
      
      .hamburger.active .menu-line:after {
        top: 0;
        transform: rotate(90deg);
      }
}