.client-wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    overflow: hidden;
    background-color: #ffffff17;
    margin-top: 100px;
    padding: 10px;

    .client-card{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline: 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        @media screen and (min-width: $min-md) {
            width: 25%;
            border-right: 1px solid rgba(255, 255, 255, 0.2);
            border-bottom: unset;
        }
        &:last-child{
            border-right: unset;
            border-bottom: unset;

        }

        
        &__img{
            width: 100px;
            height: 100px;
            display: flex;
            justify-content: center;

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }


        }
        &__content{
            width: calc(100% - 120px);
            color: $white;
            font-weight: 200;
            font-size: 13px;
            line-height: 1.5;
        }

        &.thub{
            .client-card__img{
                // width: 80px;

                img{
                    width: 60px;
                }
            }
           
        }
        &.ai{
            .client-card__img{
                // width: 80px;

                img{
                    width: 120px;
                }
            }
           
        }
        &.snowflakes{
            .client-card__img{
                // width: 80px;

                img{
                    width: 80px;
                }
            }
           
        }
    }
}